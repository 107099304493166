<template>
	<div class="bg_dark">
		<!-- 고정해더 -->
		<div class="header">
			<div class="top">
				<h1 class="logo">
					<a href="index.html"><span class="hide">Culture M.A.F.I.A.</span></a>
				</h1>
				<div class="util">
					<!-- TODO : 새로운 알림이 있을 경우 class : on 추가 -->
					<a href="" class="ico_alram on"><span class="hide">알람</span></a>
					<a href="" class="ico_profile"><span class="hide">내정보</span></a>
				</div>
			</div>
			<div class="bottom">
				<div class="nav">
					<ul>
						<!-- TODO : 메뉴 선택 시 class : active 추가 -->
						<li><a href="" class="btn_l">NFT</a></li>
						<li class="active"><a href="" class="btn_l">WALLET</a></li>
						<li><a href="" class="btn_l">STAKING</a></li>
					</ul>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 하단 네비 -->
		<div class="bottom_nav">
			<ul class="nav_bar">
				<li class="active"><a href="" class="ico_collection"><strong>NFT 컬랙션</strong></a></li>
				<li><a href="" class="ico_market"><strong>DROPS</strong></a></li>
				<li><a href="" class="ico_cartel"><strong>카르텔</strong></a></li>
			</ul>
		</div>
		<!-- //하단 네비 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-140 pb-80">
			<div class="container">
				<div class="row">
					<section>
						<div class="cont_box_3 mt-10">
							<div class="box_text">
								<!-- TODO : 해당 코인 심볼 .eht .klay -->
								<p class="wallet_logo mafi">
									<strong class="co_price">100,000.212</strong><em class="co_unit">MAFI</em>
								</p>
								<p class="pt-20">
									<button class="btn_l btn_fill_blue mr-10">입금</button>
									<button class="btn_l btn_fill_blue">출금</button>
								</p>
							</div>
						</div>
						<div class="wallet_wrap">
							<ul class="wallet_list">
								<li>
									<div class="wallet_item">
										<div class="wall_profile">
											<img src="/image/coin_mafi.png')" alt="마피코인">
										</div>
										<div class="wall_info">
											<p class="wall_info_coin">
												<strong class="co_price">5.212</strong><em class="co_unit">MAFI</em>
											</p>
											<a href="" class="wall_info_link">입출금 이력</a>
										</div>
									</div>
								</li>
								<li>
									<div class="wallet_item disable">
										<div class="wall_profile">
											<img src="/image/coin_KLAY.png')" alt="클레이든">
										</div>
										<div class="wall_info">
											<p class="wall_info_coin">
												<strong class="co_price">5.212</strong><em class="co_unit">KLAY</em>
											</p>
											<a href="" class="wall_info_link">입출금 이력</a>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="btn_center pt-30">
							<button class="btn_center btn_l btn_fill_blue disable">지갑 생성</button>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia31'
	, props: []
	, data: function(){
		return {
			program: {
				name: '지갑 상세'
			}
		}
	}

	,created() {
		this.$emit('onLoad', this.program)
	}

}
</script>